import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AokAuthService } from '../services/aok-auth.service';

export const authnGuard: CanActivateFn = async (route, state) => {
  const oneAuth: AokAuthService = inject(AokAuthService);
  const router: Router = inject(Router);
  const url = state.url.split('/').pop();
  let redirectUrl: UrlTree | boolean = false;

  const isAuthenticated = await oneAuth.isAuthenticated();
  if (isAuthenticated) {
    const isEmailVerified = await oneAuth.isEmailVerified();
    // console.log('url:', url, 'isEmailVerified:', isEmailVerified, 'isAuthenticated:', isAuthenticated);
    if (!isEmailVerified && url !== 'verify-email') {
      redirectUrl = router.parseUrl('/verify-email');
    }
  } else {
    // not authenticated
    redirectUrl = router.parseUrl('/home');
  }
  // console.log('redirectUrl:', redirectUrl);
  return redirectUrl || isAuthenticated;
};
