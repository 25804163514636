
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ChannelService } from 'stream-chat-angular';

export const conversationGuard: CanActivateFn = async (next, state) => {

  const channelService = inject(ChannelService);
  const router = inject(Router);

  if (channelService.activeChannel) {
    return true;
  }

  return router.parseUrl('/tabs/inbox')
};
