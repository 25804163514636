import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, NgZone, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  IonApp,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterLink,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { initializeApp } from 'firebase/app';
import { addIcons } from 'ionicons';
import { bugOutline, home, refresh } from 'ionicons/icons';
import { mergeMap, take, tap } from 'rxjs/operators';
import { env } from '../environments/environment';
import { AokAuthService } from './services/aok-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    AsyncPipe,
    IonRouterLink,
    IonApp,
    IonSplitPane,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonMenuToggle,
    IonItem,
    IonIcon,
    IonLabel,
    IonFooter,
    IonButton,
    IonRouterOutlet,
    IonGrid,
    IonRow,
    IonCol,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    public readonly auth: AuthService,
    public readonly aokAuthService: AokAuthService,
    private readonly ngZone: NgZone,
    private readonly router: Router,
    private readonly destroyRef: DestroyRef,
  ) {
    addIcons({ home, bugOutline, refresh });
  }

  async ngOnInit(): Promise<void> {
    await App.addListener('appUrlOpen', ({ url }) => {
      // Must run inside an NgZone for Angular to pick up the changes
      // https://capacitorjs.com/docs/guides/angular
      this.ngZone.run(() => {
        console.log('CALLBACK url: ', url);
        if (url?.startsWith(env.auth0Callback)) {
          if (url.includes('state=') && (url.includes('error=') || url.includes('code='))) {
            this.auth
              .handleRedirectCallback(url)
              .pipe(mergeMap(() => Browser.close()))
              .subscribe({
                error: (err) => console.error('handleRedirectCallback Error', err),
              });
          } else {
            Browser.close();
          }
        }

        // Callback from IdVerse
        if (url?.startsWith(env.protocol)) {
          this.aokAuthService
            .getLatestUserInformation()
            .pipe(
              tap(() => {
                const internalUrl =
                  url.includes('verify-success') ?
                    url.match(/\/\/([^\/]+\/.*)/) // get URL + params
                  : url.match(/\/\/([^\/]+)/); // get only the URL

                console.log('Redirecting to: ', internalUrl?.[1]);
                Browser.close().then(async () => await this.router.navigateByUrl(`/${internalUrl?.[1]}`));
              }),
              take(1),
              takeUntilDestroyed(this.destroyRef),
            )
            .subscribe({
              error: (err) => console.error('handleIdVerseRedirectCallback Error', err),
            });
        }
      });
    });

    if (env.isHybrid) {
      try {
        await SplashScreen.hide();
      } catch (e) {
        console.error('Plugins Error:', e);
      }
    }

    this.initialiseFirebase();
  }

  initialiseFirebase() {
    const app = initializeApp(env.firebaseConfig);
  }
}
