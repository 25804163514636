import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { PreloadAllModules, provideRouter, RouteReuseStrategy, withPreloading } from '@angular/router';
import { AuthConfig, authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { env } from './environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { CustomTemplatesService } from 'stream-chat-angular';
import { ChatClientService, StreamChatModule } from 'stream-chat-angular';

// Call the element loader before the bootstrapModule/bootstrapApplication call
defineCustomElements(window);

if (env.production) {
  enableProdMode();
}

const config: AuthConfig = {
  domain: env.domain,
  clientId: env.clientId,
  authorizationParams: {
    redirect_uri: env.auth0Callback,
    prompt: 'login',
    audience: env.audience,
  },
  errorPath: '/error',
  // For using Auth0-Angular with Ionic on Android and iOS,
  // it's important to use refresh tokens without the fallback
  useRefreshTokens: env.isHybrid,
  useRefreshTokensFallback: false,
  cacheLocation: env.isHybrid ? 'localstorage' : undefined,
  httpInterceptor: { allowedList: [`${env.apiUrl}/*`] },
};

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes, withPreloading(PreloadAllModules)),
    importProvidersFrom(CommonModule, BrowserModule, FormsModule),
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
    provideAuth0(config),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({}),
    importProvidersFrom(TranslateModule.forRoot()),
    ChatClientService,
    importProvidersFrom(CustomTemplatesService),
  ],
}).catch((err) => console.log(err));
