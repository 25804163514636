import { Router, UrlTree } from '@angular/router';
import { distinctUntilChanged, filter, Observable, of, switchMap, tap } from 'rxjs';
import { inject } from '@angular/core';
import { IdKitStatus } from '../../../../aok-backend/src/utils/aok-types';
import { AokAuthService, LoginStatusEnum } from '../services/aok-auth.service';
import { UserResponseDto } from '../../../../aok-backend/src/user/dto/create-user.dto';
import { AlertService } from '../services/alert/alert.service';

export const idVerifyGuard = (): Observable<UrlTree | boolean> => {
  const router = inject(Router);
  const aokAuthService = inject(AokAuthService);
  const alert = inject(AlertService)

  const redirectUrl = (url: string): UrlTree => {
    return router.createUrlTree([url]);
  };

  return aokAuthService.authStatus$.pipe(
    filter((status) => status !== LoginStatusEnum.LOADING),
    distinctUntilChanged(),
    switchMap(() => aokAuthService.aokUser$),
    switchMap((user: UserResponseDto | null | undefined) => {
      // Possible backend problem
      if (user === null) {
        return of(redirectUrl('error'));
      }
      // The user doesn't have started the identification
      if (!user?.idVerify) {
        return of(redirectUrl('verify-id'));
      }
      // Identification in progress
      const waitingRoom = [IdKitStatus.Pending, IdKitStatus.InProgress, IdKitStatus.Submitted];
      if (waitingRoom.includes(user?.idVerify?.status!)) {
        return of(redirectUrl('verify-status'));
      }
      // Flagged user, needs to verify id one more time
      const verificationProblem = [IdKitStatus.CompletedFlagged, IdKitStatus.Expired, IdKitStatus.Cancelled];
      if (verificationProblem.includes(user?.idVerify?.status!)) {
        alert.showErrorAlert('Something went wrong with your identification, please try again with another document', 'Ops!')
        return of(redirectUrl('verify-id'));
      }
      // User verified COMPLETED_PASS
      return of(true);
    })
  )
};
