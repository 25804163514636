import { Routes } from '@angular/router';
import { authnGuard } from './guards/authn.guard';
import { homeGuard } from './guards/home.guard';
import { conversationGuard } from './guards/conversation.guard';
import { idVerifyGuard } from './guards/id-verify.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    redirectTo: '/tabs/home',
    pathMatch: 'full',
  },

  {
    path: 'tabs',
    canActivate: [homeGuard, idVerifyGuard],
    loadComponent: () => import('./pages/tabs/tabs.page').then((m) => m.TabsPage),
    children: [
      {
        path: 'home',
        loadComponent: () => import('./pages/home/home.page').then((m) => m.HomePage),
      },
      {
        path: 'inbox',
        loadComponent: () => import('./pages/chats-inbox/chats-inbox.page').then((m) => m.InboxPage),
      },
      {
        path: 'contact-list',
        loadComponent: () => import('./pages/contact-list/contact-list.page').then((m) => m.ContactListPage),
      },
      {
        path: 'settings',
        loadComponent: () => import('./pages/settings/settings.page').then((m) => m.SettingsPage),
      },
      {
        path: '',
        redirectTo: '/tabs/home',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'verify-email',
    canActivate: [authnGuard],
    loadComponent: () => import('./pages/old/verify-email/verify-email.page').then((m) => m.VerifyEmailPage),
  },
  {
    path: 'get-started',
    loadComponent: () => import('./pages/get-started/get-started.page').then((m) => m.GetStartedPage),
  },
  {
    path: 'biometrics',
    loadComponent: () => import('./pages/biometrics/biometrics.page').then((m) => m.BiometricsPage),
  },
  {
    path: 'success' + '/:message/:nextPage',
    loadComponent: () => import('./pages/success/success.page').then((m) => m.SuccessPage),
  },
  {
    path: 'verify-id',
    // canActivate: [homeGuard],
    loadComponent: () => import('./pages/verify-id/verify-id.page').then((m) => m.VerifyIdPage),
  },
  {
    path: 'contact-profile',
    loadComponent: () => import('./pages/contact-profile/contact-profile.page').then((m) => m.ContactProfilePage),
  },
  {
    path: 'contact-profile/:userId',
    loadComponent: () => import('./pages/contact-profile/contact-profile.page').then((m) => m.ContactProfilePage),
  },
  {
    path: 'personal-info',
    loadComponent: () => import('./pages/personal-info/personal-info.page').then((m) => m.PersonalInfoPage),
  },
  {
    path: 'reset',
    loadComponent: () => import('./pages/reset/reset.page').then((m) => m.ResetPage),
  },
  {
    path: 'contact-add/:userId',
    loadComponent: () => import('./pages/contact-add/contact-add.page').then((m) => m.ContactAddPage),
  },
  {
    path: 'contact-review',
    loadComponent: () => import('./pages/contact-review/contact-review.page').then((m) => m.ContactReviewPage),
  },
  {
    path: 'contact-new',
    loadComponent: () => import('./pages/contact-new/contact-new.page').then((m) => m.ContactNewPage),
  },
  {
    path: 'verify-success' + '/:transactionId' + '/:databaseUserId',
    loadComponent: () => import('./pages/verify-success/verify-success.page').then((m) => m.VerifySuccessPage),
  },
  {
    path: 'verify-cancel',
    loadComponent: () => import('./pages/verify-cancel/verify-cancel.page').then((m) => m.VerifyCancelPage),
  },
  {
    path: 'conversation',
    canActivate: [conversationGuard],
    loadComponent: () => import('./pages/chats-conversation/chats-conversation.page').then((m) => m.ConversationPage),
  },
  {
    path: 'conversation/:conversationId',
    loadComponent: () => import('./pages/chats-conversation/chats-conversation.page').then((m) => m.ConversationPage),
  },
  {
    path: 'chats-conversation-details',
    loadComponent: () =>
      import('./pages/chats-conversation-details/chats-conversation-details.page').then(
        (m) => m.ChatsConversationDetailsPage,
      ),
  },
  {
    path: 'settings-account',
    loadComponent: () => import('./pages/settings-account/settings-account.page').then((m) => m.SettingsAccountPage),
  },
  {
    path: 'settings-privacy',
    loadComponent: () => import('./pages/settings-privacy/settings-privacy.page').then((m) => m.SettingsPrivacyPage),
  },
  {
    path: 'home-start',
    loadComponent: () => import('./pages/home-start/home-start.page').then((m) => m.HomeStartPage),
  },
  {
    path: 'verify-status',
    loadComponent: () => import('./pages/verify-status/verify-status.page').then((m) => m.VerifyStatusPage),
  },
  {
    path: 'chats-group-chat-details',
    canActivate: [conversationGuard],
    loadComponent: () =>
      import('./pages/chats-group-chat-details/chats-group-chat-details.page').then((m) => m.ChatsGroupChatDetailsPage),
  },
  {
    path: 'warning',
    loadComponent: () => import('./pages/warning/warning.page').then((m) => m.WarningPage)
  },
  {
    path: 'error',
    loadComponent: () => import('./pages/error/error.page').then((m) => m.ErrorPage)
  },
  {
    path: 'chats-channel-media',
    canActivate: [conversationGuard],
    loadComponent: () => import('./pages/chats-channel-media/chats-channel-media.page').then( m => m.ChatsChannelMediaPage)
  },
];
