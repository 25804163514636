import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { BiometricAuth } from '@aparajita/capacitor-biometric-auth';
import { AokAuthService } from '../services/aok-auth.service';
import { PreferencesService } from '../services/preferences.service';

export const homeGuard: CanActivateFn = async (next, state) => {
  const preferencesService = inject(PreferencesService);
  const router = inject(Router);
  const authService = inject(AokAuthService);

  const timesLaunched = await preferencesService.getTimesLaunched();
  const hasBiometrics = await preferencesService.getHasBiometrics();
  const biometryResult = await BiometricAuth.checkBiometry();
  const idVerified = await preferencesService.getIsIdVerified();
  let route = '';

  // let route = state.url;
  if (!timesLaunched) {
    route = 'get-started';
  } else if (!hasBiometrics) {
    route = 'biometrics';
  } else if (!(await authService.isAuthenticated())) {
    await authService.login();
  }
  console.log('HOME.GUARD route:', route || state.url);
  return route ? router.parseUrl(route) : true;
};
