import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { Toast } from '@capacitor/toast';
import { catchError, throwError } from 'rxjs';

function showToastError(text: string) {
  Toast.show({
    text,
    duration: 'long',
    position: 'center',
  });
}

export const errorInterceptorFn: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMsg = '';
      if (error.error instanceof ErrorEvent) {
        errorMsg = `Client Error: ${error.error.message}`;
      } else {
        errorMsg = error.status ? `HttpStatus ${error.status}: ` : '';
        errorMsg += `${error.message}`;
      }
      showToastError(errorMsg);
      console.error(errorMsg);
      return throwError(() => errorMsg);
    }),
  );
};
