<ion-app>
  <ion-split-pane contentId="main">
    <ion-menu contentId="main">
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle autoHide="false">
            <ion-item routerDirection="root" routerLink="/tabs/home">
              <ion-icon slot="start" name="home"></ion-icon>
              <ion-label> Home </ion-label>
            </ion-item>
            <ion-item routerDirection="root" routerLink="/verify-id">
              <ion-icon slot="start" name="mail"></ion-icon>
              <ion-label> Verify ID </ion-label>
            </ion-item>
            <ion-item routerDirection="root" routerLink="/reset">
              <ion-icon slot="start" name="refresh"></ion-icon>
              <ion-label> Reset </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      @if (auth.isAuthenticated$ | async) {
      <ion-footer class="ion-no-border">
        <ion-toolbar color="translucent">
          <ion-menu-toggle autoHide="false">
            <ion-button
              (click)="oneAuth.logout()"
              expand="block"
              class="pad20 ion-margin-bottom"
            >
              Logout
            </ion-button>
          </ion-menu-toggle>
        </ion-toolbar>
      </ion-footer>
      }
    </ion-menu>
    <ion-router-outlet id="main"> </ion-router-outlet>
  </ion-split-pane>
</ion-app>
