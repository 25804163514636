import { AsyncPipe } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  IonApp,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterLink,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { chatbubbles, create, home, mail, refresh, settings } from 'ionicons/icons';
import { mergeMap } from 'rxjs/operators';
import { env } from '../environments/environment';
import { AokAuthService } from './services/aok-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    AsyncPipe,
    IonRouterLink,
    IonApp,
    IonSplitPane,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonMenuToggle,
    IonItem,
    IonIcon,
    IonLabel,
    IonFooter,
    IonButton,
    IonRouterOutlet,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public oneAuth: AokAuthService,
    private ngZone: NgZone,
  ) {
    addIcons({ home, create, mail, chatbubbles, settings, refresh });
    oneAuth.initializeAuthState();
  }

  async ngOnInit(): Promise<void> {
    await App.addListener('appUrlOpen', ({ url }) => {
      // Must run inside an NgZone for Angular to pick up the changes
      // https://capacitorjs.com/docs/guides/angular
      this.ngZone.run(() => {
        if (url?.startsWith(env.auth0Callback)) {
          if (url.includes('state=') && (url.includes('error=') || url.includes('code='))) {
            this.auth
              .handleRedirectCallback(url)
              .pipe(mergeMap(() => Browser.close()))
              .subscribe({
                error: (err) => console.error('handleRedirectCallback Error', err),
              });
          } else {
            Browser.close();
          }
        }
      });
    });

    if (env.isHybrid) {
      try {
        await SplashScreen.hide();
      } catch (e) {
        console.error('Plugins Error:', e);
      }
    }
  }
}
